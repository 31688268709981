<template>
  <div>
    <label for="the-facts-full-name">Full Name At Birth:</label>
    <InputFormContainer
      type="input"
      meta-key="the-facts-full-name"
      @save="saveChanges"
      :height="100"
    />
    <div class="row">
      <div class="col-md-6">
        <label for="the-facts-birthday">Date of Birth:</label>
        <InputFormContainer
          type="input"
          meta-key="the-facts-birthday"
          @save="saveChanges"
          :height="100"
        />
      </div>
      <div class="col-md-6">
        <label for="the-facts-birthplace">Place of Birth:</label>
        <InputFormContainer
          type="input"
          meta-key="the-facts-birthplace"
          @save="saveChanges"
          :height="100"
        />
      </div>
    </div>

    <label for="the-facts-parents"
      >Your Parents and any additional caretakers along the way</label
    >
    <InputFormContainer
      meta-key="the-facts-parents"
      @save="saveChanges"
      :height="100"
    />

    <label for="the-facts-siblings"
      >Your Siblings full, half, blended families</label
    >
    <InputFormContainer
      meta-key="the-facts-siblings"
      @save="saveChanges"
      :height="100"
    />

    <label for="the-facts-children"
      >Your Children include dates of birth, name of other parent</label
    >
    <InputFormContainer
      meta-key="the-facts-children"
      @save="saveChanges"
      :height="100"
    />

    <label for="the-facts-other-family">Other Family Members</label>
    <InputFormContainer
      meta-key="the-facts-other-family"
      @save="saveChanges"
      :height="100"
    />

    <label for="the-facts-spouse">Spouse</label>
    <InputFormContainer
      meta-key="the-facts-spouse"
      @save="saveChanges"
      :height="100"
    />

    <label for="the-facts-ex-spouses">Previous Spouses</label>
    <InputFormContainer
      meta-key="the-facts-ex-spouses"
      @save="saveChanges"
      :height="100"
    />

    <label for="the-facts-education"
      >Education &amp; Degrees Earned List schools in order</label
    >
    <InputFormContainer
      meta-key="the-facts-education"
      @save="saveChanges"
      :height="100"
    />

    <label for="the-facts-places-lived">Places You've Lived</label>
    <InputFormContainer
      meta-key="the-facts-places-lived"
      @save="saveChanges"
      :height="100"
    />

    <label for="the-facts-careers"
      >Career &amp; Job Positions You've Held</label
    >
    <InputFormContainer
      meta-key="the-facts-careers"
      @save="saveChanges"
      :height="100"
    />

    <label for="the-facts-volunteer-services-activities"
      >Volunteer &amp; Service Activities throughout your life</label
    >
    <InputFormContainer
      meta-key="the-facts-volunteer-services-activities"
      @save="saveChanges"
      :height="100"
    />

    <label for="the-facts-historical-political-events"
      >Historical &amp; Political Events that were impactful and
      memorable</label
    >
    <InputFormContainer
      meta-key="the-facts-historical-political-events"
      @save="saveChanges"
      :height="100"
    />
  </div>
</template>

<script>
import InputFormContainer from "../InputFormContainer.vue";
export default {
  components: {
    InputFormContainer,
  },
  data() {
    return {
      content: "",
    };
  },
  methods: {
    saveChanges({ metaKey, content }) {
      switch (metaKey) {
        default:
          this.$store.dispatch("updateUserMeta", [
            metaKey,
            content,
            ({ message }) => {
              this.$toast.open({
                message,
                position: "bottom-left",
                type: "success",
              });
            },
          ]);
          break;
      }
    },
  },
};
</script>

<style scoped>
div .input-form {
  height: 500px;
}
label {
  margin-top: 20px;
}
</style>